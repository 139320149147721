import React from 'react'
import PropTypes from 'prop-types'

// Code from https://github.com/xurei/react-jotform-embed/blob/master/src/react-jotform-embed.js
// Rewrote to remove findDOMNode per https://github.com/yannickcr/eslint-plugin-react/issues/678

const iframeStyle = {
  width: '100%',
  height: '300px',
}

export default class JotFormEmbed extends React.Component {
  static propTypes = {
    src: PropTypes.string.isRequired,
    className: PropTypes.string,
  }
  
  constructor(props) {
    super(props)
    this.handleIframeMessage = this.handleIframeMessage.bind(this)
  }
  
  handleIframeMessage(e) {
    const props = this.props
    if (!e.data.split) {
      return
    }
    const args = e.data.split(':')
    const formId = args[2]
    if (!!this.iframe && (!formId || props.src.endsWith(formId))) {
      switch (args[0]) {
        case 'scrollIntoView':
          this.iframe.scrollIntoView()
          break
        case 'setHeight':
          this.iframe.style.height = `${args[1]}px`
          break
        case 'collapseErrorPage':
          if (this.iframe.clientHeight > global.innerHeight) {
            this.iframe.style.height = `${global.innerHeight}px`
          }
          break
        case 'reloadPage':
          global.location.reload()
          break
        default:
          break
      }
      const isJotForm = (e.origin.indexOf('jotform') > -1)
      if (isJotForm && 'contentWindow' in this.iframe && 'postMessage' in this.iframe.contentWindow) {
        const urls = {
          'docurl': encodeURIComponent(global.document.URL),
          'referrer': encodeURIComponent(global.document.referrer),
        }
        this.iframe.contentWindow.postMessage(JSON.stringify({'type': 'urls', 'value': urls}), '*')
      }
    }
  }
  
  componentWillMount() {
    if (global.addEventListener) {
      global.addEventListener('message', this.handleIframeMessage, false)
    }
    else if (global.attachEvent) {
      global.attachEvent('onmessage', this.handleIframeMessage)
    }
  }
  
  componentWillUnmount() {
    if (global.removeEventListener) {
      global.removeEventListener('message', this.handleIframeMessage, false)
    }
    else if (global.detachEvent) {
      global.detachEvent('onmessage', this.handleIframeMessage)
    }
  }
  
  render() {
    const props = this.props
    return (
      <iframe ref={node => this.iframe = node} style={iframeStyle} className={props.className} src={props.src} frameBorder={0} title="Ebedded JotForm" scrolling="no" />
    )
  }
  
  shouldComponentUpdate(nextProps) {
    const props = this.props
    return props.src !== nextProps.src || props.className !== nextProps.className
  }
}
